import { Pricing as ComplexPricing } from "@moirei/complex-pricing";
import { min } from "lodash";
import type { Pricing } from "@/models/pricing";
import type { Currency } from "@/types/commerce";

export const getPrice = (
  pricing: Pricing,
  {
    quantity = 1,
    comparison,
    useNilText,
    currency,
    format = true,
  }: {
    quantity?: number;
    comparison?: Pricing;
    useNilText?: string;
    currency?: Currency;
    format?: boolean;
  } = {}
): string | number => {
  let price = ComplexPricing.make(pricing).price(quantity);
  const comparisonPrice = comparison
    ? ComplexPricing.make(comparison).price(quantity)
    : 0;

  price = min([price, comparisonPrice]) || 0;

  if (!format) {
    return price;
  }

  if (!currency) {
    currency = useCurrency().currency.value;
  }

  return formatPrice(price, currency, useNilText);
};

export const getMinPrice = (
  prices: {
    pricing: Pricing;
    comparison?: Pricing;
  }[],
  {
    quantity = 1,
    useNilText,
    currency,
    format = true,
  }: {
    quantity?: number;
    useNilText?: string;
    currency?: Currency;
    format?: boolean;
  } = {}
): string | number => {
  const convertedPrices = prices.map(({ pricing, comparison }) =>
    getPrice(pricing, {
      comparison,
      quantity,
      useNilText,
      format: false,
    })
  );

  const price = min(convertedPrices) || 0;

  if (!format) {
    return price;
  }

  if (!currency) {
    currency = useCurrency().currency.value;
  }

  return formatPrice(price, currency, useNilText);
};

export const formatPrice = (
  price: number | string,
  currency?: Currency,
  useNilText?: string
): string => {
  if (price == 0 && useNilText) {
    return useNilText;
  }

  if (!currency) {
    currency = useCurrency().currency.value;
  }

  return getCurrencyRepresentation(currency) + Number(price).toFixed(2);
};

export const getCurrencyRepresentation = (currency: Currency): string => {
  const code = match(String(currency.code).toUpperCase(), {
    AUD: "A",
    USD: "US",
    CAD: "CA",
    EUR: "EU",
    default: currency.code,
  });

  return code + currency.symbol;
};
